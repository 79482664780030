<template lang="pug">
  .filters
    .row.m-b-10
      .col-md-6.col-lg-3
        .filter-title {{ $t("price_calendar.filters.ota") }}
        AppDropdown.ota-list(
          allow-empty
          close-on-select
          :value="filters.ota"
          :items="otas"
          :is-item-disabled="isOtaDisabled"
          @select="$emit('change:ota', $event)"
          @remove="$emit('change:ota', {})"
        )
      .col-md-6.col-lg-3
        .filter-title {{ $t("price_calendar.filters.shop") }}
        AppDropdown.shop-name(
          disabled
          v-b-tooltip.hover
          v-if="isEmpty(filters.ota)"
          :placeholder="$t('inventory_management.select_shop')"
          :title="$t('price_calendar.filters.select_ota_first')"
        )
        AppDropdown.shop-name(
          v-else
          allow-empty
          close-on-select
          searchable
          :value="filters.shop"
          :items="shops"
          :loading="shopsLoading"
          @select="$emit('change:shop', $event)"
          @remove="$emit('change:shop', {})"
        )
      .col-md-6.col-lg-3
        .filter-title {{ $t("price_calendar.filters.car_class") }}
        AppDropdown.car-class-name(
          disabled
          v-b-tooltip.hover
          v-if="isEmpty(filters.shop) || isEmpty(filters.ota)"
          :placeholder="$t('price_calendar.filters.select_car_classes')"
          :title="$t('price_calendar.filters.select_shop_first')"
        )
        AppDropdown.car-class-name(
          v-else
          allow-empty
          searchable
          close-on-select
          :value="filters.carClass"
          :is-item-disabled="isCarClassDisabled"
          :items="carClasses"
          order-direction="preorder"
          :loading="carClassesLoading"
          @select="$emit('change:car-class', $event)"
          @remove="$emit('change:car-class', {})"
        )
      .col-md-6.col-lg-3
        .toggler-item.w-100
          .filter-title {{ $t("price_calendar.filters.insurance") }}
          Toggler.m-t-12(
            :value="filters.insurance"
            :items="insuranceList"
            @change="$emit('change:insurance', $event)"
          )
    .bottom-filters
      .left-side
        .filter-title {{ $t("price_calendar.filters.departure_date") }}
        .d-flex
          AppDatepicker(
            :value="departureDate"
            :range="true"
            :clearable="false"
            :disabled-date="disabledDate"
            @change="changeDepartureDate"
          )
          AppButton.today.short(
            name="today"
            title="inventory_management.today"
            @click="setToday"
          )

      .right-side
        .buttons
          span(@click="openPlanSettings")
            PlanStatusBadge(
              v-if="!isEmpty(currentPlan)"
              :export-status="currentPlan.export_status"
            )
          AppButton.short.rule-settings(
            :disabled="hasDenyPermission('rule_settings')"
            title="general_settings.rule_settings.tab_title"
            @click="openRuleSettings"
          )
          AppSaveButton.short(
            title="price_calendar.filters.update_prices"
            :disabled="disabledSave || !hasEditPermission('price_calendar')"
            @save="$emit('save-changes')"
          )
</template>

<script>
  // mixins
  import withConfirmation from "@/mixins/withConfirmation"
  import withPermissions from "@/mixins/withPermissions"

  // misc
  import { startOfCurrentDate } from "@/helpers/price-calendar"
  import { add as dateAdd, startOfDay, endOfDay } from "date-fns"
  import { isEmpty } from "lodash-es"

  export default {
    props: {
      currentPlan: {
        type: Object,
        default: () => new Object()
      },
      filters: {
        type: Object,
        default: () => new Object()
      },
      shops: {
        type: Array,
        default: () => new Array()
      },
      carClasses: {
        type: Array,
        default: () => new Array()
      },
      departureDate: {
        type: Array,
        default: () => new Array()
      },
      shopsLoading: {
        type: Boolean,
        default: false
      },
      carClassesLoading: {
        type: Boolean,
        default: false
      },
      otas: {
        type: Array,
        default: () => new Array()
      },
      insuranceList: {
        type: Array,
        default: () => new Array()
      },
      timeSlotList: {
        type: Array,
        default: () => new Array()
      },
      disabledSave: {
        type: Boolean,
        default: false
      }
    },

    components: {
      AppDropdown: () => import("@/components/elements/AppDropdown"),
      AppDatepicker: () => import("@/components/elements/AppDatepicker"),
      AppButton: () => import("@/components/elements/AppButton"),
      AppSaveButton: () => import("@/components/elements/AppButton/Save"),
      PlanStatusBadge: () => import("@/pages/PriceManagement/PlanSettings/BasicSettings/PlanStatusBadge"),
      Toggler: () => import("./Toggler")
    },

    mixins: [withConfirmation, withPermissions],

    computed: {
      minDate({ currentPlan }) {
        return new Date(currentPlan.open_start_date)
      },

      maxDate({ currentPlan }) {
        return new Date(currentPlan.open_end_date)
      }
    },

    methods: {
      isEmpty,

      disabledDate(date) {
        return date < startOfDay(this.minDate) || date > endOfDay(this.maxDate)
      },

      openPlanSettings() {
        const query = {
          ota_id: this.filters.ota.id,
          shop_id: this.filters.shop.id,
          car_class_id: this.filters.carClass.id
        }

        this.$router.push({ name: "BasicSettings", query })
      },

      changeDepartureDate(departureDate) {
        this.$emit("change:departure-date", departureDate)
      },

      setToday() {
        const startDate = startOfCurrentDate < this.minDate ? this.minDate : startOfCurrentDate
        const afterMonthDate = dateAdd(startOfCurrentDate, { months: 1 })
        const endDate = afterMonthDate > this.maxDate ? this.maxDate : afterMonthDate

        this.changeDepartureDate([startDate, endDate])
      },

      openRuleSettings() {
        this.$router.push({ name: "RuleSettings" })
      },

      isCarClassDisabled({ selectable }) {
        return !selectable
      },

      isOtaDisabled({ active }) {
        return !active
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"
  @import "@/assets/styles/mixins/common.sass"

  $filter-height: 33px

  .filters
    +filter-title

    ::v-deep
      .app-select
        margin-left: -6px

      .app-button
        height: $filter-height

      .datepicker
        input
          height: $filter-height

    .togglers
      align-items: center
      display: flex
      justify-content: space-between

      .toggler-item
        width: 50%

    .today
      border: 1px solid $default-purple
      color: $default-purple
      height: $filter-height
      text-transform: uppercase

    .bottom-filters
      display: flex
      justify-content: space-between
      flex-wrap: wrap
      gap: 0.5rem

      .right-side
        margin-top: 24px

        .buttons
          align-items: center
          display: flex
          justify-content: flex-end
          gap: 7px

          .plan-status
            cursor: pointer

          .rule-settings
            background: $default-purple
            color: $default-white
            font-size: 0.8rem
</style>
